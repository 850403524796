import { Component } from "react";
import { NAV_LINKS } from "../global-navbar/navbar-data";
import { SidePanel } from "./sidebar.style-component";
import { t } from "i18next";
import Accordion from "../../libs/accordion/accordion.component";
import styled from "styled-components";

interface SidebarState {
  openAccordionIndex: string | null;
}
export class Sidebar extends Component<{}, SidebarState> {
  public items = NAV_LINKS;
  public allowedDomains = ["co", "mx", "pe", "cl", "ec", "om"];
  public isDomainAllowed = this.allowedDomains.includes(
    localStorage.getItem("spv_topLevelDomain")
  );

  constructor(props: {}) {
    super(props);
    this.state = {
      openAccordionIndex: null,
    };
  }

  handleAccordionToggle = (id: string) => {
    this.setState((prevState) => ({
      openAccordionIndex: prevState.openAccordionIndex === id ? null : id,
    }));
  };

  render() {
    const { openAccordionIndex } = this.state;
    return (
      <SidePanel id="sidePanel" className="d-lg-none">
        <div
          className="container-fluid ml-auto mr-0"
          style={{
            paddingTop: "10px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <div
            className="row"
            style={{ marginRight: "0px", marginLeft: "0px" }}
          >
            <div className="col-xs-12 px-0">
              <img
                style={{ marginTop: "26px" }}
                className="mb-3 mx-3 lazyload"
                alt="Logo seguro para viaje"
                width="26"
                height="40"
                data-src="https://res.cloudinary.com/pervolare-org/image/upload/v1647957207/spv/sites/images/header/icon-spv_sl1dh0.svg"
              />
              <div className="closebtn ">
                <img
                  className="btn lazyload"
                  onClick={this.closeNav}
                  data-src="https://res.cloudinary.com/pervolare-org/image/upload/v1697670677/spv/sites/images/header/close_fvbi0k.svg"
                  alt="btn close"
                  width={50}
                  height={50}
                />
              </div>
              <div className="mt-4 pb-3 content-links">
                {this.items.map((item, index) => (
                  <Accordion
                    id={`accordion-${index}`}
                    key={index.toString()}
                    isOpen={openAccordionIndex === `accordion-${index}`}
                    onToggle={this.handleAccordionToggle}
                    header={
                      <div className="change-styles">
                        <span className="f-16 Gotham">
                          {t(item.displayName)}
                        </span>
                        <span className="arrow">
                          <img
                            width="15"
                            height="11"
                            alt="Arrow down"
                            className="lazyload"
                            data-src="https://res.cloudinary.com/pervolare-org/image/upload/v1697676143/spv/sites/images/header/arrow_b59qn9.svg"
                            style={{ marginLeft: "20px" }}
                          />
                        </span>
                      </div>
                    }
                    body={
                      <ul className="content-ul">
                        {item.children.map((child, i) => (
                          <li className="mb-3 start-xs py-2" key={`child-${i}`}>
                            <a
                              className="text-dark f-16 d-flex Gotham"
                              href={child.link}
                              title={t(child.displayName)}
                            >
                              {t(child.displayName)}
                            </a>
                          </li>
                        ))}
                      </ul>
                    }
                  />
                ))}
                <div className="center-xs mt-3">
                  <a
                    className="btn Gotham-bold btn-report-emergency"
                    href="https://api.whatsapp.com/send?phone=12018016266&text=Hola,%20necesito%20solicitar%20una%20asistencia%20o%20reportar%20un%20siniestro."
                    target="_blank"
                  >
                    {t("Report emergency")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SidePanel>
    );
  }

  closeNav() {
    document.getElementById("sidePanel").style.width = "0";
  }
}
