export const NAV_LINKS = [
  {
    displayName: "Services",
    children: [
      {
        displayName: "Plans and benefits",
        link: "/planes-coberturas",
      },
      {
        displayName: "Additional Services",
        link: "/beneficios-adicionales",
      },
      {
        displayName: "Health Assistance",
        link: "/alianzas",
      },
    ],
  },
  {
    displayName: "Help",
    children: [
      {
        displayName: "Consult certificate",
        link: "/consulta-documentos",
      },
      {
        displayName: "Frequently Asked Questions",
        link: "/preguntas-frecuentes",
      },
      {
        displayName: "Contact us",
        link: "/contactos",
      },
    ],
  },
  {
    displayName: "Us",
    children: [
      {
        displayName: "About us",
        link: "/quienes-somos",
      },
      {
        displayName: "Blog",
        link: "/blog/home",
      },
    ],
  },
];
